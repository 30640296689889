<template>
    <div id="app">

        <PageLoader v-if="!pageIsLoaded" />
        <div class="page-first-load-ph"></div>

        <div class="main-wrapper">
            <nav-bar></nav-bar>
            <transition name="page" mode="out-in">
                <router-view />
            </transition>
            <footer-bar></footer-bar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'
import PageLoader from '@/components/PageLoader'

export default {
    components: { NavBar, FooterBar, PageLoader },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.navbar')) {
                return
            }

            if (st >= 10) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        },
    },

    created() {
        // NavBar Follow
        this.toogleNavBarStatus()
        window.addEventListener('scroll', () => {
            this.toogleNavBarStatus()
        })
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        contentIsLoaded: {
            handler(contentIsLoaded) {
                if (contentIsLoaded) {
                    // Page loaded with Data
                    document.querySelector('.page-first-load-ph').remove()
                }
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

#app {
    min-height: 100vh;
}

.page-first-load-ph {
    height: 100vh;
}

.page-enter-active,
.page-leave-active {
    transition-duration: 1.471s;
    transition-property: opacity, transform;
    // transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);

    [hero-transition-group] {
        transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s,
        transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
    }
}

.page-enter,
.page-leave-active {
    [hero-transition-group] {
        transform: translate3d(0, 45px, 0);
        opacity: 0;
    }
}
</style>
