<template>
    <header class="navbar" @click.prevent="menuIsOpen = false">
        <router-link :to="{ name: 'Home-' + $route.meta.lang }"><img width="150" height="74" src="@/assets/img/logo.svg" class="logo" alt="Logo Mellön" /></router-link>
        <router-link :to="{ name: 'Home-' + $route.meta.lang }"><img width="30" height="44" src="@/assets/img/logo-m.svg" class="logo-m" alt="Logo Mellön" /></router-link>

        <div class="right">
            <nav>
                <router-link :to="{ name: 'Bieres-' + $route.meta.lang }">{{ $t('Bières') }}</router-link>
                <router-link :to="{ name: 'Bouffe-' + $route.meta.lang }">{{ $t('Bouffe') }}</router-link>
                <router-link :to="{ name: 'Evenements-' + $route.meta.lang }">{{ $t('Événements') }}</router-link>
                <router-link :to="{ name: 'About-' + $route.meta.lang }">{{ $t('À propos') }}</router-link>
                <a :href="'#contact'" v-scroll-to:-0>{{ $t('Contact') }}</a>
            </nav>

            <a @click.prevent.stop="goToLink(globalsIsLoaded ? globals.contact.reseaux.facebook : '')" :href="globalsIsLoaded ? globals.contact.reseaux.facebook : ''" target="_blank" rel="noopener noreferrer" class="social">
                <svg height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM22.0845 20.8785V31.7596H17.5825V20.8789H15.3333V17.1293H17.5825V14.878C17.5825 11.819 18.8525 10 22.4609 10H25.465V13.7501H23.5872C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0845 17.1288H25.4862L25.0882 20.8785H22.0845Z"
                        fill="#2E3E30"
                    />
                </svg>
            </a>
            <a @click.prevent.stop="goToLink(globalsIsLoaded ? globals.contact.reseaux.instagram : '')" :href="globalsIsLoaded ? globals.contact.reseaux.instagram : ''" target="_blank" rel="noopener noreferrer" class="social">
                <svg
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM15.6027 9.39778C16.7405 9.346 17.104 9.33333 20.001 9.33333H19.9976C22.8954 9.33333 23.2577 9.346 24.3955 9.39778C25.531 9.44978 26.3066 9.62956 26.9866 9.89334C27.6888 10.1656 28.2822 10.53 28.8755 11.1234C29.4688 11.7162 29.8333 12.3114 30.1066 13.0129C30.3688 13.6912 30.5488 14.4663 30.6022 15.6018C30.6533 16.7396 30.6666 17.1032 30.6666 20.0001C30.6666 22.897 30.6533 23.2597 30.6022 24.3975C30.5488 25.5326 30.3688 26.308 30.1066 26.9864C29.8333 27.6877 29.4688 28.2829 28.8755 28.8758C28.2828 29.4691 27.6886 29.8344 26.9873 30.1069C26.3086 30.3707 25.5326 30.5504 24.397 30.6024C23.2592 30.6542 22.8968 30.6669 19.9996 30.6669C17.1029 30.6669 16.7396 30.6542 15.6018 30.6024C14.4665 30.5504 13.6911 30.3707 13.0124 30.1069C12.3113 29.8344 11.7162 29.4691 11.1235 28.8758C10.5304 28.2829 10.166 27.6877 9.8933 26.9862C9.62974 26.308 9.44996 25.5328 9.39774 24.3973C9.34618 23.2595 9.33329 22.897 9.33329 20.0001C9.33329 17.1032 9.34663 16.7394 9.39752 15.6016C9.44863 14.4665 9.62863 13.6912 9.89308 13.0127C10.1664 12.3114 10.5309 11.7162 11.1242 11.1234C11.7171 10.5302 12.3122 10.1658 13.0138 9.89334C13.692 9.62956 14.4671 9.44978 15.6027 9.39778Z"
                        fill="#2E3E30"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.0441 11.2556C19.2299 11.2553 19.4298 11.2554 19.6455 11.2555L20.001 11.2556C22.849 11.2556 23.1866 11.2658 24.3113 11.3169C25.3513 11.3645 25.9157 11.5383 26.2917 11.6843C26.7895 11.8776 27.1444 12.1087 27.5175 12.482C27.8909 12.8554 28.122 13.2109 28.3158 13.7087C28.4618 14.0843 28.6358 14.6487 28.6831 15.6887C28.7342 16.8132 28.7453 17.151 28.7453 19.9977C28.7453 22.8444 28.7342 23.1821 28.6831 24.3066C28.6355 25.3466 28.4618 25.9111 28.3158 26.2866C28.1224 26.7844 27.8909 27.1389 27.5175 27.512C27.1442 27.8853 26.7897 28.1164 26.2917 28.3098C25.9162 28.4564 25.3513 28.6298 24.3113 28.6773C23.1868 28.7284 22.849 28.7395 20.001 28.7395C17.1528 28.7395 16.8152 28.7284 15.6907 28.6773C14.6507 28.6293 14.0863 28.4555 13.7101 28.3095C13.2123 28.1162 12.8567 27.8851 12.4834 27.5117C12.11 27.1384 11.8789 26.7837 11.6851 26.2857C11.5391 25.9102 11.3651 25.3457 11.3178 24.3057C11.2667 23.1813 11.2565 22.8435 11.2565 19.995C11.2565 17.1465 11.2667 16.8105 11.3178 15.6861C11.3654 14.6461 11.5391 14.0816 11.6851 13.7056C11.8785 13.2078 12.11 12.8523 12.4834 12.4789C12.8567 12.1056 13.2123 11.8745 13.7101 11.6807C14.0861 11.534 14.6507 11.3607 15.6907 11.3129C16.6747 11.2685 17.0561 11.2551 19.0441 11.2529V11.2556ZM25.6949 13.0267C24.9882 13.0267 24.4149 13.5994 24.4149 14.3063C24.4149 15.013 24.9882 15.5863 25.6949 15.5863C26.4016 15.5863 26.9749 15.013 26.9749 14.3063C26.9749 13.5996 26.4016 13.0263 25.6949 13.0263V13.0267ZM14.5233 20.0001C14.5233 16.975 16.9759 14.5224 20.0009 14.5223C23.0261 14.5223 25.4781 16.975 25.4781 20.0001C25.4781 23.0253 23.0263 25.4768 20.0012 25.4768C16.976 25.4768 14.5233 23.0253 14.5233 20.0001Z"
                        fill="#2E3E30"
                    />
                    <path
                        d="M20.0009 16.4445C21.9645 16.4445 23.5565 18.0363 23.5565 20.0001C23.5565 21.9637 21.9645 23.5557 20.0009 23.5557C18.0371 23.5557 16.4453 21.9637 16.4453 20.0001C16.4453 18.0363 18.0371 16.4445 20.0009 16.4445Z"
                        fill="#2E3E30"
                    />
                </svg>
            </a>

            <router-link v-if="contentIsLoaded && $route.meta.lang === 'fr'" :to="content.urls['en'] + extraQuery" class="lang">EN</router-link>
            <router-link v-else :to="contentIsLoaded && content.urls['fr'] ? content.urls['fr'] + extraQuery : ''" class="lang">FR</router-link>
        </div>

        <div id="hamburger" @click.prevent.stop="showMenu" :class="{ open: menuIsOpen }">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

        <section class="menu-mobile" :class="{ open: menuIsOpen }" @click.prevent="menuIsOpen = false">
            <nav>
                <router-link :to="{ name: 'Bieres-' + $route.meta.lang }">{{ $t('Bières') }}</router-link>
                <router-link :to="{ name: 'Bouffe-' + $route.meta.lang }">{{ $t('Bouffe') }}</router-link>
                <router-link :to="{ name: 'Evenements-' + $route.meta.lang }">{{ $t('Événements') }}</router-link>
                <router-link :to="{ name: 'About-' + $route.meta.lang }">{{ $t('À propos') }}</router-link>

                <a :href="'#contact'" v-scroll-to:-0>{{ $t('Contact') }}</a>
            </nav>
            <div class="social-wrap">
                <a @click.prevent.stop="goToLink(globalsIsLoaded ? globals.contact.reseaux.facebook : '')" v-if="globalsIsLoaded" :href="globals.contact.reseaux.facebook" target="_blank" rel="noopener noreferrer" class="social">
                    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM22.0845 20.8785V31.7596H17.5825V20.8789H15.3333V17.1293H17.5825V14.878C17.5825 11.819 18.8525 10 22.4609 10H25.465V13.7501H23.5872C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0845 17.1288H25.4862L25.0882 20.8785H22.0845Z"
                            fill="#2E3E30"
                        />
                    </svg>
                </a>
                <a @click.prevent.stop="goToLink(globalsIsLoaded ? globals.contact.reseaux.instagram : '')" v-if="globalsIsLoaded" :href="globals.contact.reseaux.instagram" target="_blank" rel="noopener noreferrer" class="social">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM15.6027 9.39778C16.7405 9.346 17.104 9.33333 20.001 9.33333H19.9976C22.8954 9.33333 23.2577 9.346 24.3955 9.39778C25.531 9.44978 26.3066 9.62956 26.9866 9.89334C27.6888 10.1656 28.2822 10.53 28.8755 11.1234C29.4688 11.7162 29.8333 12.3114 30.1066 13.0129C30.3688 13.6912 30.5488 14.4663 30.6022 15.6018C30.6533 16.7396 30.6666 17.1032 30.6666 20.0001C30.6666 22.897 30.6533 23.2597 30.6022 24.3975C30.5488 25.5326 30.3688 26.308 30.1066 26.9864C29.8333 27.6877 29.4688 28.2829 28.8755 28.8758C28.2828 29.4691 27.6886 29.8344 26.9873 30.1069C26.3086 30.3707 25.5326 30.5504 24.397 30.6024C23.2592 30.6542 22.8968 30.6669 19.9996 30.6669C17.1029 30.6669 16.7396 30.6542 15.6018 30.6024C14.4665 30.5504 13.6911 30.3707 13.0124 30.1069C12.3113 29.8344 11.7162 29.4691 11.1235 28.8758C10.5304 28.2829 10.166 27.6877 9.8933 26.9862C9.62974 26.308 9.44996 25.5328 9.39774 24.3973C9.34618 23.2595 9.33329 22.897 9.33329 20.0001C9.33329 17.1032 9.34663 16.7394 9.39752 15.6016C9.44863 14.4665 9.62863 13.6912 9.89308 13.0127C10.1664 12.3114 10.5309 11.7162 11.1242 11.1234C11.7171 10.5302 12.3122 10.1658 13.0138 9.89334C13.692 9.62956 14.4671 9.44978 15.6027 9.39778Z"
                            fill="#2E3E30"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.0441 11.2556C19.2299 11.2553 19.4298 11.2554 19.6455 11.2555L20.001 11.2556C22.849 11.2556 23.1866 11.2658 24.3113 11.3169C25.3513 11.3645 25.9157 11.5383 26.2917 11.6843C26.7895 11.8776 27.1444 12.1087 27.5175 12.482C27.8909 12.8554 28.122 13.2109 28.3158 13.7087C28.4618 14.0843 28.6358 14.6487 28.6831 15.6887C28.7342 16.8132 28.7453 17.151 28.7453 19.9977C28.7453 22.8444 28.7342 23.1821 28.6831 24.3066C28.6355 25.3466 28.4618 25.9111 28.3158 26.2866C28.1224 26.7844 27.8909 27.1389 27.5175 27.512C27.1442 27.8853 26.7897 28.1164 26.2917 28.3098C25.9162 28.4564 25.3513 28.6298 24.3113 28.6773C23.1868 28.7284 22.849 28.7395 20.001 28.7395C17.1528 28.7395 16.8152 28.7284 15.6907 28.6773C14.6507 28.6293 14.0863 28.4555 13.7101 28.3095C13.2123 28.1162 12.8567 27.8851 12.4834 27.5117C12.11 27.1384 11.8789 26.7837 11.6851 26.2857C11.5391 25.9102 11.3651 25.3457 11.3178 24.3057C11.2667 23.1813 11.2565 22.8435 11.2565 19.995C11.2565 17.1465 11.2667 16.8105 11.3178 15.6861C11.3654 14.6461 11.5391 14.0816 11.6851 13.7056C11.8785 13.2078 12.11 12.8523 12.4834 12.4789C12.8567 12.1056 13.2123 11.8745 13.7101 11.6807C14.0861 11.534 14.6507 11.3607 15.6907 11.3129C16.6747 11.2685 17.0561 11.2551 19.0441 11.2529V11.2556ZM25.6949 13.0267C24.9882 13.0267 24.4149 13.5994 24.4149 14.3063C24.4149 15.013 24.9882 15.5863 25.6949 15.5863C26.4016 15.5863 26.9749 15.013 26.9749 14.3063C26.9749 13.5996 26.4016 13.0263 25.6949 13.0263V13.0267ZM14.5233 20.0001C14.5233 16.975 16.9759 14.5224 20.0009 14.5223C23.0261 14.5223 25.4781 16.975 25.4781 20.0001C25.4781 23.0253 23.0263 25.4768 20.0012 25.4768C16.976 25.4768 14.5233 23.0253 14.5233 20.0001Z"
                            fill="#2E3E30"
                        />
                        <path
                            d="M20.0009 16.4445C21.9645 16.4445 23.5565 18.0363 23.5565 20.0001C23.5565 21.9637 21.9645 23.5557 20.0009 23.5557C18.0371 23.5557 16.4453 21.9637 16.4453 20.0001C16.4453 18.0363 18.0371 16.4445 20.0009 16.4445Z"
                            fill="#2E3E30"
                        />
                    </svg>
                </a>
            </div>
            <router-link v-if="contentIsLoaded && $route.meta.lang === 'fr'" :to="content.urls['en'] + extraQuery" class="lang">EN</router-link>
            <router-link v-else :to="contentIsLoaded && content.urls['fr'] ? content.urls['fr'] + extraQuery : ''" class="lang">FR</router-link>
            <router-link :to="{ name: 'Home-' + $route.meta.lang }"
                ><img src="@/assets/img/logo.svg" class="mobile-logo" alt="Logo Mellön"
            /></router-link>
        </section>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            menuIsOpen: false,
        }
    },
    methods: {
        showMenu() {
            if (this.menuIsOpen) {
                this.menuIsOpen = false
            } else {
                this.menuIsOpen = true
            }
        },
        goToLink(link) {
            window.open(link, '_blank')
        },
    },
    computed: {
        extraQuery() {
            if (this.$route && this.$route.query && this.$route.query.section) {
                return `?section=${this.$route.query.section}`
            }

            return ''
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.menu-mobile {
    position: fixed;
    top: 65px;
    right: -100%;
    width: 100%;
    height: calc(100vh - 65px);
    overflow-y: scroll;
    background-color: #fff;
    transition: all 0.4s ease;
    padding: 7vw 5vw;

    &.open {
        right: 0;
    }

    nav {
        a {
            text-transform: uppercase;
            color: $colorAccent1;
            font-weight: 600;
            font-size: 15px;
            margin-right: 50px;
            letter-spacing: 2px;
            width: 100%;
            display: block;
            margin-bottom: 30px;
            transition: all 0.5s ease;

            &:hover {
                color: $colorAccent3;
            }
        }
    }

    .social-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .social {
        svg {
            width: 30px;
            margin-right: 20px;

            path {
                transition: all 0.2s ease;
            }

            &:hover {
                path {
                    fill: $colorAccent3;
                }
            }
        }
    }

    .lang {
        text-transform: uppercase;
        color: $colorAccent1;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 2px;
        transition: all 0.2s ease;

        &:hover {
            color: $colorAccent3;
        }
    }

    .mobile-logo {
        width: 175px;
        position: absolute;
        left: 50%;
        bottom: 7vw;
        transform: translate(-50%, 0);
    }
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vw 5vw;
    background-color: transparent;
    z-index: 100;
    transition: all 0.5s ease;
    border-bottom: 1px solid #fff;
    background-color: #fff;

    &.sticky {
        background-color: #fff;
        padding: 15px 5vw;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .logo {
            width: 0px;
            opacity: 0;
        }
        .logo-m {
            width: 30px;
            opacity: 1;
        }
        .right {
            nav {
                a {
                    font-size: 13px;
                }
            }
        }
    }

    .logo {
        width: 150px;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .logo-m {
        position: absolute;
        top: 15px;
        left: 5vw;
        width: 0;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .right {
        display: flex;
        align-items: center;

        nav {
            a {
                text-transform: uppercase;
                color: $colorAccent1;
                font-weight: 600;
                font-size: 15px;
                margin-right: 50px;
                letter-spacing: 2px;
                transition: all 0.5s ease;

                &:hover {
                    color: $colorAccent3;
                }
            }
        }

        .social {
            svg {
                width: 30px;
                margin-right: 20px;

                path {
                    transition: all 0.2s ease;
                }

                &:hover {
                    path {
                        fill: $colorAccent3;
                    }
                }
            }
        }

        .lang {
            text-transform: uppercase;
            color: $colorAccent1;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 2px;
            transition: all 0.2s ease;

            &:hover {
                color: $colorAccent3;
            }
        }
    }

    #hamburger {
        width: 27px;
        height: 20px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        display: none;
    }

    #hamburger span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $colorAccent1;
        border-radius: 0;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    #hamburger span:nth-child(1) {
        top: 0px;
    }

    #hamburger span:nth-child(2),
    #hamburger span:nth-child(3) {
        top: 8px;
    }

    #hamburger span:nth-child(4) {
        top: 16px;
    }

    #hamburger.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    #hamburger.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #hamburger.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #hamburger.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    @media screen and (max-width: 1024px) {
        background-color: #fff;
        padding: 15px 5vw;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .logo {
            width: 0px;
            opacity: 0;
        }
        .logo-m {
            width: 30px;
            opacity: 1;
        }
        .right {
            nav {
                a {
                    font-size: 13px;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        .right {
            nav {
                a {
                    margin-right: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        height: 65px;
        margin-bottom: 7vw;

        &.sticky {
            position: fixed;
        }
        .right {
            display: none;
        }
        .logo-m {
            width: 25px;
        }
        #hamburger {
            display: block;
        }
    }
}
</style>
