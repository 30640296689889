import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'Home-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/bieres',
        name: 'Bieres-fr',
        component: () => import(/* webpackChunkName: "bieres" */ '../views/bieres/Index.vue'),
        meta: { lang: 'fr', json: 'bieres' },
    },
    {
        path: '/en/beers',
        name: 'Bieres-en',
        component: () => import(/* webpackChunkName: "bieres" */ '../views/bieres/Index.vue'),
        meta: { lang: 'en', json: 'bieres' },
    },
    {
        path: '/bouffe',
        name: 'Bouffe-fr',
        component: () => import(/* webpackChunkName: "bouffe" */ '../views/bouffe/Index.vue'),
        meta: { lang: 'fr', json: 'bouffe' },
    },
    {
        path: '/en/food',
        name: 'Bouffe-en',
        component: () => import(/* webpackChunkName: "bouffe" */ '../views/bouffe/Index.vue'),
        meta: { lang: 'en', json: 'bouffe' },
    },
    {
        path: '/bieres/anciennement-en-fut',
        name: 'BieresArchive-fr',
        component: () => import(/* webpackChunkName: "bieresArchive" */ '../views/bieres/Archive.vue'),
        meta: { lang: 'fr', json: 'bieres' },
    },
    {
        path: '/en/beers/past-tap-beers',
        name: 'BieresArchive-en',
        component: () => import(/* webpackChunkName: "bieresArchive" */ '../views/bieres/Archive.vue'),
        meta: { lang: 'en', json: 'bieres' },
    },
    {
        path: '/biere/:slug',
        name: 'Biere-fr',
        component: () => import(/* webpackChunkName: "biere" */ '../views/bieres/Entry.vue'),
        meta: { lang: 'fr', json: 'biereEntry', slug: true },
    },
    {
        path: '/en/beer/:slug',
        name: 'Biere-en',
        component: () => import(/* webpackChunkName: "biere" */ '../views/bieres/Entry.vue'),
        meta: { lang: 'en', json: 'biereEntry', slug: true },
    },
    {
        path: '/evenements',
        name: 'Evenements-fr',
        component: () => import(/* webpackChunkName: "evenements" */ '../views/evenements/Index.vue'),
        meta: { lang: 'fr', json: 'evenements' },
    },
    {
        path: '/en/events',
        name: 'Evenements-en',
        component: () => import(/* webpackChunkName: "evenements" */ '../views/evenements/Index.vue'),
        meta: { lang: 'en', json: 'evenements' },
    },
    {
        path: '/evenements/passes',
        name: 'EvenementsArchive-fr',
        component: () => import(/* webpackChunkName: "evenementsArchive" */ '../views/evenements/Archive.vue'),
        meta: { lang: 'fr', json: 'evenements' },
    },
    {
        path: '/en/events/past',
        name: 'EvenementsArchive-en',
        component: () => import(/* webpackChunkName: "evenementsArchive" */ '../views/evenements/Archive.vue'),
        meta: { lang: 'en', json: 'evenements' },
    },
    {
        path: '/evenement/:slug',
        name: 'Evenement-fr',
        component: () => import(/* webpackChunkName: "evenement" */ '../views/evenements/Entry.vue'),
        meta: { lang: 'fr', json: 'evenementEntry', slug: true },
    },
    {
        path: '/en/event/:slug',
        name: 'Evenement-en',
        component: () => import(/* webpackChunkName: "evenement" */ '../views/evenements/Entry.vue'),
        meta: { lang: 'en', json: 'evenementEntry', slug: true },
    },
    {
        path: '/a-propos',
        name: 'About-fr',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { lang: 'fr', json: 'aboutUs' },
    },
    {
        path: '/en/about-us',
        name: 'About-en',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { lang: 'en', json: 'aboutUs' },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'en', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 425)

    next()
})

export default router
